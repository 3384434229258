$sizeIcon: 40px;
$heightIcon: 39px;

//color variables
$main: #ec0000;
$main-over: #b80202;
$dark: #25303b;
$white: #ffffff;
$grey: #727272;
$lightgrey: #d8d8d8;
$darkgrey: #444444;
$aqua: #8cbabd;
$darkaqua: #137e84;
$black: #000;
$darkblue: #25303b;
$green: #488048;

// home
$item-inner: #dbe0e3;
$background: #f7f7f7;
$grey-sub: #a2a9b0;
$grey-sub2: #a2a9b0;

// forms
$input-border: #eef0f2;
$input-background: #fafbfc;
$input-bottom-border: #8cbabd;
$row-border: #dddddd;

$f-text: "Santander Text", "Lato", Arial, Sans-Serif;
$f-stext-only: "Santander Text", Arial, Sans-Serif;
