.icon-success {
  height: 52px;
  width: 53px;
  background: url("/assets/images/DOC001.png") no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
}
.icon-error {
  height: 52px;
  width: 53px;
  background: url("/assets/images/DOC002.png") no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
}
.modal-footer {
  justify-content: center;
  border-top: 0px;
  text-align: center;
}
.close {
  position: absolute;
  top: -25px;
  right: 0;
  color: $white;
}
.modal-content {
  padding: 50px 50px;
}
.text-description {
  color: $darkgrey;
  font-family: $f-text;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}
.icon-main > span {
  height: 52px;
  width: 53px;
}
.modal {
  &.show {
    display: block;
  }
}
