.ps--footer {
  font-size: 0.8rem;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background: $darkgrey;
  padding: 0.5rem 1rem;
  width: 100%;
  color: white;
  z-index: 9;
  .bi {
    margin-right: 0.5rem;
  }
}
@media (min-width: 992px) {
  .ps--footer {
    font-size: 0.8rem;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background: $darkgrey;
    padding: 0.5rem 1rem;
    width: 100%;
    color: white;
    .bi {
      margin-right: 0.5rem;
    }
  }
}
@media (min-width: 1200px) {
  .ps--footer {
    font-size: 0.8rem;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background: $darkgrey;
    padding: 0.5rem 1rem;
    width: 100%;
    color: white;
    .bi {
      margin-right: 0.5rem;
    }
  }
  // esto corresponde a la pantalla no al footer, pantallas de mas de 1200px

}



.ps--full-container {
  height: 100%;
}
