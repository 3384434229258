.rowSectionCard {
    @media (min-width: 720px) {
        margin-top: 1rem;
    }

    .sectionCard{
        display: flex;
        flex-direction: column;
        padding: 0.2rem 1.3rem !important;
        /* row-gap: 1rem; */
        background-color: rgb(187 197 197 / 22%);
        border-radius: 10px;
        @media (max-width: 720px) {
            margin-top: 1rem;
        }
    }
}

.fixed-width-detail{
    @media (max-width: 720px) {
        padding: 0rem 1.4rem;
    }

    @media (min-width: 960px) {
        max-width: 90%;
        margin: 0 auto;
      }
      @media (min-width: 1440px) {
        max-width: 90%;
        margin: 0 auto;
      }
}

