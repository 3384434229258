.ps--header {
  .presc-correos {
    h1 {
      font-weight: 100;
      text-shadow: 1px 1px lightgrey;
      font-size: 1.4rem;
      padding-top: 10px;
      @media (min-width: 992px) {
        margin-left: 6rem;
      }
    }
  }
  background: $main;
  .ps--header-main-logo,
  .ps--header-secondary-logo {
    margin-right: 0.5rem;
    max-height: 2.7rem;
  }

  .ps--header-main-logo {
    @media (min-width: 576px) {
      margin-left: 3.75rem;
    }
  }

  .ps--header-disconnect-icon {
    max-height: 2.7rem;
    margin-right: 2rem;
    margin-left: -2.2rem;
    &:hover{
      cursor: pointer;
    }
  }
}
.ps--header,
.ps--header-nav,
.ps--header-disconnect {
  font-family: $f-stext-only;
}

.ps--header {
  color: white;
  min-height: 3.75rem;
}

.ps--header-sup {
  min-height: 3.75rem;
  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.ps--header-nav {
  color: $darkgrey;
  font-size: 0.9rem;
}
.ps--header-disconnect {
  color: $main;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.ps--header-nav {
  background: white;
  height: 2.3rem;
  ul {
    list-style-type: none;
    list-style-position: outside;
    li {
      line-height: 2.3rem;
      color: $darkgrey;
      margin-right: 1rem;
      &:hover {
        color: $main;
        cursor: pointer;
      }
    }
  }
}

.ps--header-user-container {
  margin-right: 1.5rem;
  span {
    display: block;
  }
  .ps--header-user-username {
    text-transform: uppercase;
    font-weight: bold;
  }
}

.ps--header-personal-area {
  @media (min-width: 1024px) {
    margin-right: 3.75rem;
  }
  min-width: 70px;
}

.ps--personal-area-editar,
.ps--header-personal-area {
  a {
    &:hover {
      cursor: pointer;
    }
  }
}
.ps--header-personal-area {
  position: relative;
  @media (max-width: 576px) {
    display: none;
  }

  img {
    max-height: 1.5rem;
    display: block;
    margin: 0 auto 0.15rem auto;
  }
  span {
    display: block;
  }
  .ps--header-personal-area-tooltip {
    right: calc(100% - 4.5rem);
    top: 4rem;
    border: 1px solid #d0d0d0;
    box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    position: absolute;
    display: block;
    width: 303px;
    height: 384px;
    background: white;
    z-index: 100;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -20px;
      right: 15.5px;
      background: url("../../assets/images/triangulo.png") no-repeat center
        center;
      width: 40px;
      height: 20px;
    }
  }
  .ps--header-personal-area-tooltip-correos {
    right: calc(100% - 4.5rem);
    top: 4rem;
    border: 1px solid #d0d0d0;
    box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    position: absolute;
    display: block;
    width: 303px;
    height: 300px;
    background: white;
    z-index: 100;
    .ps-personal-area-editar-correos {
      color: $main;

      padding-left: 104px;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -20px;
      right: 15.5px;
      background: url("../../assets/images/triangulo.png") no-repeat center
        center;
      width: 40px;
      height: 20px;
    }
  }
  .ps--header-personal-area-tooltip-no-mp {
    right: calc(100% - 4.5rem);
    top: 4rem;
    border: 1px solid #d0d0d0;
    box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    position: absolute;
    display: block;
    width: 303px;
    height: 385px;
    background: white;
    z-index: 100;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -20px;
      right: 15.5px;
      background: url("../../assets/images/triangulo.png") no-repeat center
        center;
      width: 40px;
      height: 20px;
    }
  }
}

.ps--personal-area-name {
  color: black;
  .ps--personal-area-editar {
    color: $main;
    margin-left: 1rem;
  }
  .ps--header-personal-area-dni {
    color: $grey-sub;
    margin-left: 5.4rem;
  }
}
.ps--personal-area-circle {
  background: #ec0000;
  border-radius: 50%;
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 1rem;
  left: 1rem;
}
.ps--personal-area-img {
  position: absolute;
  top: 0.5rem;
  width: 3rem;
  height: 3rem;
}
.ps--header-user-username-ap {
  margin-top: 1.4rem;
  margin-left: 5.5rem;
  margin-bottom: 0px;
}

.ps--personal-area-data {
  margin-bottom: 0rem;
  padding-left: 0.5rem;
  margin-left: 0.4rem;
  margin-right: 1.4rem;
  max-width: 16rem;
  p {
    color: #a2a9b0;
    font-size: 0.8rem;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 0rem;
    margin-top: 1.2rem;
  }
  span {
    color: black;
    font-size: 0.8rem;
    letter-spacing: 0;
    line-height: 15px;
  }
}

.ps--personal-area-input {
  margin-bottom: 0rem;
  margin-right: 1.4rem;
  max-width: 16rem;
  label {
    color: #a2a9b0;
    font-size: 0.8rem;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 0rem;
    margin-top: 1.2rem;
    display: flex;
    width: auto;
  }
  input {
    margin-top: 0.2rem;
    margin-left: 5rem;
    margin-bottom: 0rem;
    font-family: $f-text;
    color: #727272;
    font-size: 1rem;
    padding: 0 0.5rem;
    background: #fafbfc;
    height: 2rem !important;
    width: 10rem;
    margin-left: 0;
    border: 1px solid #eef0f2;
    border-bottom: 1px solid #8cbabd;
    border-radius: 0;
  }
}
.ps--cambiar-pass {
  margin-top: 1rem;
  color: #ec0000;
  font-size: 0.88rem;
  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.ps--header,
.ps--header-disconnect {
  font-size: 0.75rem;
  line-height: 1.125rem;
}
// area personal no mapfre

.ps--personal-area-div {
  margin-top: 3rem;
}
.ps--header-pos {
  padding-left: 1.1rem;
}
.ps--header-editar-guardar {
  padding-left: 0.5rem;
}
