.ps--login {
  font-family: $f-stext-only;
  background-size: cover;
  min-height: 100%
}

.ps--login-logo {
  background: white;
  padding: 1.5rem 0 2rem 0;
  img {
    max-height: 40px;
    margin-left: 30px;
  }
}

.ps--login-separator {
  height: 2rem;
}

.ps--login-box {
  min-width: 22.5rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.93);
  border-radius: 5px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
}

.ps--login-box-title,
.ps--login-box-recovery,
.ps--login-box-warning,
.ps--login-box-back {
  color: $dark;
  font-weight: bold;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
}

.ps--login-box-back {
  font-size: 1rem;
  font-weight: normal;
}

.ps--login-box-warning,
.ps--login-box-recovery {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $main;
}

.ps--login-box-title {
  width: 90%;
  font-size: 1rem;
  line-height: 1.5rem;
}

.ps--login-box-info {
  color: $dark;
  font-size: 0.75rem;
}

.ps--login-box-recovery,
.ps--login-box-back {
  &,
  &:hover {
    color: $dark;
    cursor: pointer;
  }
}
.ps--login-btn {
  padding: 0.6rem;
}
