.ps--home-pcomerciales {
  font-family: $f-text;
  min-height: 46.5rem;
  .ps--home-pcomerciales-column {
    padding: 1rem 3rem;
  }
}

.ps--home-pcomerciales-cabecera {
  a {
    margin: 0 0 1rem 0;
    width: 100%;
  }
  img {
    width: 100%;
  }
}

.ps--home-pcomerciales-box {
  border: 1px solid $lightgrey;
  background-color: white;
  @media (max-width: 1200px) {
    margin-bottom: 2rem;
  }
  .pieEnlaceBox {
    text-align: center;
    margin-right: auto;
    margin-bottom: 12px;
    a {
      color: $main;
      :hover {
        cursor: pointer;
      }
    }
  }
}

.ps--home-pcomerciales-noticias {
  /*min-height: 24rem;*/
  h1 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
  h2 {
    font-size: 0.9rem;
    margin: 0;
  }
  p {
    margin: 0 0 0.2rem 0;
    padding: 0;
    font-size: 0.9rem;
    color: grey;
  }
  span {
    display: block;
    font-size: 0.9rem;
    color: $grey-sub;
    margin: 0.3rem 0;
  }
}

.ps--home-pcomerciales-udocumento {
  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
    padding: 0;
    font-size: 0.9rem;
  }
  :hover {
    cursor: pointer;
  }
  span {
    display: block;
    font-size: 0.9rem;
    color: $grey-sub;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px dashed $lightgrey;
  }
  .ps--home-pcomerciales-udocumento-header {
    display: flex;
    margin-top: 1rem;
    border-bottom: 1px dashed $lightgrey;
    h1 {
      padding-left: 0.5rem;
      font-size: 1.1rem;
      font-weight: bold;
      padding-top: 2rem;
      margin-left: 0.5rem;
    }

    em {
      font-size: 42px;
      color: #137e84;
    }
  }
}

.ps--home-pcomerciales-noticia {
  border-bottom: 1px solid $lightgrey;
  margin-bottom: 0.8rem;
  a {
    text-decoration: none;
    color: black;
  }
  :hover {
    cursor: pointer;
  }
}

.ps--home-pcomerciales-spinner {
  color: $main;
  width: 3rem;
  height: 3rem;
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.ps--home-pcomerciales-facturas {
  width: 100%;
  margin: 1rem 0;
  a {
    text-decoration: none;
    color: black;
  }
  h1 {
    padding-top: 0.8rem;
    margin-bottom: 0;
    font-size: 1.1rem;
    font-weight: bold;
  }

  em {
    font-size: 42px;
    color: #137e84;
  }
  p {
    width: 100%;
    padding: 0;
    font-size: 0.9rem;
  }
  :hover {
    cursor: pointer;
  }
}
.ps--pointer {
  :hover {
    cursor: pointer;
  }
}
