input[type=text],
input[type=password],
input[type=email],
input[type=tel],
input[type=date],
textarea,
select {
  font-family: $f-stext-only;
  color: $grey;
  font-size: 1rem;
  padding: 0 .5rem;
  background: $input-background;
  height: 3rem;
  border: 1px solid $input-border;
  border-bottom: 1px solid $input-bottom-border;
  border-radius: 0;
  &:hover,
  &:focus,
  &:focus-visible {
    outline: none;
  }
  &.block {
    width: 100%;
  }
}

.ps--form-invalid {
  box-shadow: 0 0 5px 1px $main;
}

/*
input[type=text]:invalid,
input[type=email]:invalid,
input[type=tel]:invalid,
input[type=date]:invalid {
  box-shadow: 0 0 5px 1px $main;
}
*/
.btn {
  &.ps--btn {
    min-width: 10rem;
    height: 2.5rem;
    font-family: $f-stext-only;
    font-size: 0.875rem;
    border-radius: 1.25rem;
    &.ps--btn-primary {
      background: $main;
      color: white;
      &:hover:not(:disabled) {
        background: $main-over;
      }
    }
    &:disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
    &.ps--btn-cancel{
      background: #ffffff;
      color: $main;
      box-shadow: 0 1px 6px rgba(68,68,68,0.2);
      border: none;
      &:hover:not(:disabled) {
        background: #f6f6f6;
        color: #cc0000;
      }
    }
  }


}

.btn,
.btn-form {
  &:hover,
  &:focus,
  &:focus-visible {
    outline: none;
  }
}
