.ps--espacio-cita {
  select {
    font-family: $f-text;
    appearance: none;
    padding-right: 2.6rem;
    margin-left: 1rem;
  }
  .bi {
    color: $darkaqua;
    font-size: 1.5rem;
    position: absolute;
    right: 0.05rem;
    top: 0.45rem;
    border-left: 1px solid $input-border;
    padding-left: 0.45rem;
  }
}

.ps--calendario {
  input {
    font-family: $f-text;
    color: $grey;
    font-size: 1rem;
    padding: 0 0.5rem;
    background: $input-background;
    height: 3rem;
    border: 1px solid $input-border;
    border-bottom: 1px solid $input-bottom-border;
    border-radius: 0;
  }
  .bi-calendar {
    color: $darkaqua;
    font-size: 1.5rem;
    position: absolute;
    /* right: 1rem; */
    left: 8.1rem;
    top: 5.45rem;
    border-left: 1px solid $input-border;
  }

  #btn-calendar-diapreferente{
    top: 2rem;
  }
}

.ps--calendario-correos {
  input {
    position: relative;
    font-family: $f-text;
    color: $grey;
    font-size: 1rem;
    padding: 0 0.5rem;
    background: $input-background;
    height: 3rem;
    border: 1px solid $input-border;
    border-bottom: 1px solid $input-bottom-border;
    border-radius: 0;
  }
  .bi-calendar {
    color: $darkaqua;
    font-size: 1.5rem;
    position: absolute;
    right: 0;
    border-left: 1px solid $input-border;
  }
}

.ps--fake-label{
  p{
    font-family: $f-stext-only;
    font-size: 0.875rem;
    color: #444444;
    margin-bottom: 0.625rem;
  }

}

.ps--enviar-disabled{
  background-color: #f0acac !important;
}

.ps--label-combo{
  font-family: $f-stext-only;
  font-size: 0.875rem;
  display: block;
  color: $darkgrey;
  margin-bottom: 0.625rem;
}
