.ps--home-intro {
  margin-top: 3.125rem;
  margin-bottom: 1.5rem;
  font-family: $f-stext-only;
  color: white;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  .ps--home-intro-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.875rem;
    margin-bottom: 1.875rem;
  }
}

.ps--home{
  // min-height: 100%;
  background: round;
  background-size: cover;
}

.ps--home-heigth-defect{
  min-height: calc(100% - 115px);

}

.ps--home-heigth-defect2 {
min-height: calc(100% - 97px);
}

.ps--home-item {
  margin-bottom: 10px;
  &.col,
  &[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.ps--item {
  background: white;
  border-radius: 3px;
  padding: 1.3125rem;
  min-height: 5.75rem;
  box-shadow: inset 0 0 2px $item-inner;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .ps--item-image {
    filter: invert(26%) sepia(80%) saturate(7495%) hue-rotate(356deg) brightness(100%) contrast(89%);
    margin-right: 0.625rem;
    img {
      max-width: 1.25rem;
      position: relative;
      top: -0.15rem;
    }
  }
  .ps--item-info {
    font-family: $f-stext-only;
    span {
      display: block;
    }
    color: $darkgrey;
    font-size: 0.875rem;
    line-height: 1.25rem;
    .ps--item-info-description {
      font-size: 0.64rem;
      line-height: 0.875rem;
    }
  }
}
