.ps--noticias {
  margin-bottom: 5rem;
  min-height: 46.5rem;
  .ps--form-back {
    text-transform: uppercase;
  }
}

.ps--detalle-noticias {
  font-family: $f-text;
  min-height: 46.5rem;
  .ps--detalle-noticias-column{
    padding: 1rem 3rem;
  }
  .ps--detalle-noticias-box{
    background-color: white;
  }
}
.ps--form-container,
.ps--noticias-box-container,
.ps--noticias-box-container,
.ps--noticias-box-container-table {
  position: relative;
  margin-top: 0.5rem;
  background: white;
  border: 1px solid $item-inner;
  padding: 1.25rem 2.5rem;
  @media (max-width: 960px) {
    padding: 1.25rem;
  }
}

.ps--buscador-noticias {
  input {
    font-family: $f-text;
    color: $grey;
    font-size: 1rem;
    padding: 0 0.5rem;
    background: $input-background;
    height: 3rem;
    width: 18rem;
    border: 1px solid $input-border;
    border-bottom: 1px solid $input-bottom-border;
    border-radius: 0;
  }
  .bi {
    color: $darkaqua;
    font-size: 1.5rem;
    position: absolute;
    right: 0.05rem;
    top: 0.45rem;
    border-left: 1px solid $input-border;
    padding-left: 0.45rem;
  }
}

.ps--noticias-table,
.ps--noticias-table-pagination,
.ps--noticias-title {
  font-family: $f-stext-only;
}

.ps--noticias-table {
  thead {
    tr {
      th {
        font-size: 0.625rem;
        font-weight: bold;
        border: none;
        border-right: 0px;
      }
    }
  }
  tbody {
    tr {
      &:first-of-type {
        td {
          border-top: 1px solid $dark;
          border-right: 0px;
        }
      }
      td {
        font-size: 0.75rem;
        color: $darkgrey;
        border-right: 0px;
        &.ps--noticias-table-date {
          min-width: 6.25rem;
          a{
            color: black;
            text-decoration: none;

          }
        }
      }
    }
  }
}

.ps--noticias-table-pagination {
  font-size: 0.75rem;
  .ngx-pagination {
    margin: 0;
    padding: 0;
    li,
    a {
      border-radius: 3px;
    }
    .current {
      background: $darkaqua;
    }
  }
}

.ps--detalle-noticias-box{
  padding: 1rem;
  display: block;
  a{
    display: block;
  }
  img{
    width: fit-content;
  }
  h1{
    font-size: 1.5rem;
    font-weight: bold;
  }
  h5{
    font-size: 1rem;
    font-weight: bold;
    color: $main;
  }
  p{
    color: grey;
  }
  span{
    display: block;
    font-weight: bold;
    margin-bottom: 1rem;
  }
}
