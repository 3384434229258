.ps--circuito {
  min-height: 46.5rem;
  .ps--form-back {
    text-transform: uppercase;
  }
}

.ps--circuito-logo {
  background-size: auto;
  padding-bottom: 20px;
}

.ps--circuito-punto {
  position: relative;
  border-radius: 50%;
  background-color: $lightgrey;
  width: 20px;
  height: 20px;
  &::before {
    content: "";
    position: absolute;
    top: 15%;
    left: 15%;
    background-color: orange;
    width: 70%;
    height: 70%;
    border-radius: 50%;
  }
}

.ps--circuito-linea {
  background: transparent url("../../assets/images/grey-line.png") repeat-y top
    center;
}

.ps--circuito-tramo {
  p {
    width: 95%;
    margin: 0;
  }
  &.fecha {
    p {
      text-align: right;
    }
  }
  &.desc {
    p {
      margin: 2rem 0;
      padding: 1rem;
      text-align: left;
      border: 1px solid $lightgrey;
      border-radius: 10px;
    }
  }
}
