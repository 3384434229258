// Globales
@import "styles/partials/ps-typos";
@import "styles/partials/ps-variables";
@import "styles/partials/ps-mixins";
@import "styles/partials/ps-grid";
@import "styles/partials/ps-blocks";
@import "styles/partials/ps-forms";
@import "styles/partials/ps-tables";

// Componentes
@import "styles/components/ps-header";
@import "styles/components/ps-login";
@import "styles/components/ps-home";
@import "styles/components/ps-footer";
@import "styles/components/ps-alert";
@import "styles/components/ps-seguimiento";
@import "styles/components/ps-facturas";
@import "styles/components/ps-alta";
@import "styles/components/ps-circuito";
@import "styles/components/ps-document-presc";
@import "styles/components/ps-home-pcomerciales";
@import "styles/components/ps-noticias";
@import "styles/components/ps-detalle-lead";
@import "styles/components/ps-gestion-empleados";

// Boostrap rewrites
@import "styles/partials/ps-rewrites";

// Bootstrap icons
@import "~bootstrap-icons/font/bootstrap-icons.css";
