.ps--form-alta {
  // margin-bottom: 5rem;
}

.ps--form-back .ps--form-back-bottom,
.ps--form-print,
.ps--form-button-radio,
.ps--input-box,
.ps--form-disclaimer {
  font-family: $f-stext-only;
}

.ps--form-back,
.ps--form-back-invoices,
.ps--form-print,
.ps--form-privacity {
  margin-top: 1rem;
  font-family: $f-stext-only;
  margin-bottom: 0.25rem;
  &:hover {
    cursor: pointer;
  }
}

.ps--form-title-seguimiento {
  &:hover {
    cursor: auto;
  }
}

.ps--form-title-arrowleft, .ps--seguimiento-table-eye {
  &:hover {
    cursor: pointer;
  }
}

.ps--seguimiento-table-eye{
  font-size: 1rem;
  color: $darkaqua;
}

.ps--form-back,
.ps--form-back-bottom {
  font-size: 1.875rem;
  color: $main;
  font-weight: 300;
  .bi {
    font-size: 1.6rem;
    margin-right: 0.75rem;
  }
}
.ps--form-print,
.ps--form-privacity {
  font-size: 0.85rem;
  line-height: 1.25rem;
  &,
  &:hover {
    color: $darkaqua;
  }
  .bi {
    margin-left: 0.4rem;
    font-size: 1.2rem;
  }
}

.ps--form-privacity {
  margin-right: 0.5rem;
  padding-right: 0.5rem;
  border-right: 1px solid $row-border;
}

.ps--form-back-bottom,
.ps--form-back-bottom-detail {
  font-size: 1.4rem;
  padding-left: 1rem;
  font-style: normal;
  &:hover {
    cursor: pointer;
  }
}

.ps--form-back-bottom-detail {
  font-family: $f-stext-only;
  color: $main;
}
.ps--form-user-warning{
  font-family: $f-stext-only;
  color: $main;
  font-size: 0.8rem;
  text-align: center;
}

.ps--form-button-radio {
  font-size: 0.875rem;
  padding: 0.625rem 1.475rem;
  color: $darkgrey;
  background: white;
  border: 1px solid $row-border;
  border-radius: 6px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  margin-right: 0.625rem;
  &.active,
  &.btn-form-active {
    background: $darkaqua;
    color: white;
    box-shadow: none;
    border: 1px solid $darkaqua;
  }
  &:disabled,
  &.btn-form-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.ps--button-radio-personType {
  font-size: 0.875rem;
  padding: 0.625rem 1.475rem;
  color: $darkgrey;
  background: white;
  border: 1px solid $row-border;
  border-radius: 6px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  margin-right: 0.625rem;
  &.activeButton,
  &.btn-form-active {
    background: $darkaqua;
    color: white;
    box-shadow: none;
    border: 1px solid $darkaqua;
    cursor: pointer;
  }

  &:disabled,
  &.btn-form-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.ps--form-button-radio-avanzado {
  .ps--form-button-radio-avanzado-tittle {
    font-size: 0.875rem;
    color: #444444;
  }
  .ps--form-button-radio-avanzado-botonera {
    padding: 0.3rem;
    background-color: #e6ecef;
    border-radius: 5px;
    width: max-content;
    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
    }
    label {
      padding: 10px 40px;
    }
    label:hover {
      background-color: white;
      color: $darkaqua;
      cursor: pointer;
    }
  }

  input[type="radio"]:checked + label {
    color: $darkaqua;
    font-weight: bold;
    background-color: white;
  }
}

.ps--form-no-border,
.ps--form-with-border {
  & > .col,
  & > [class*="col-"] {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}

.ps--form-with-border {
  border-bottom: 1px dashed $row-border;
}

.ps--form-title {
  width: 100%;
  font-family: $f-stext-only;
  font-size: 1rem;
  font-weight: bold;
  color: $darkgrey;
  margin-bottom: 1.25rem;
}

.ps--form-subtitle {
  width: 100%;
  font-family: $f-stext-only;
  position: relative;
  top: -20px;
  font-size: 0.8rem;
  font-weight: lighter;
  color: $darkgrey;
}

.ps--form-title-cita {
  width: 100%;
  font-family: $f-stext-only;
  font-size: 0.9rem;
  color: $darkgrey;
  margin-bottom: 1.25rem;
}

.ps--input-box,
.ps--input-box-inline {
  label {
    font-size: 0.875rem;
    display: block;
    color: $darkgrey;
    margin-bottom: 0.625rem;
  }
  input,
  textarea,
  select {
    display: block;
    width: 100%;
  }
  textarea {
    height: 8rem;
    resize: none;
  }
  .textLeft {
    margin: 0;
    font-size: 0.675rem;
    color: $lightgrey;
  }
}

.ps--label-horariopreferente {
  font-size: 0.875rem;
  display: block;
  color: #444444;
  margin-bottom: 0.625rem;
  font-family: $f-stext-only;
}

.ps--input-box-inline {
  label,
  input,
  textarea,
  select {
    display: inline;
    width: auto;
  }
}
.ps--custom-coments-form {
  input {
    appearance: none;
    padding-right: 2.6rem;
    border: 1px solid #eef0f2;
    border-bottom: 1px solid #8cbabd;
    border-radius: 0;
    height: 3rem;
  }
}

.ps--new-custom-select {
  select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: $input-background url("/assets/images/dropdown.png") no-repeat
      right;
    background-size: contain;
    &:active {
      border: 1px solid #8cbabd;
    }
  }
}

.ps--custom-select {
  select {
    padding-right: 5px;
    appearance: none;
    background: url(../../assets/images/dropdown.png) #fafbfc no-repeat right;
    background-size: contain;
  }
  .bi {
    color: $darkaqua;
    font-size: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 0.45rem;
    border-left: 1px solid $input-border;
    padding-left: 0.45rem;
  }
}

.ps--custom-select-phone {
  display: inline-flex;
  select {
    width: 12rem;
    margin-right: 5px;
  }

  input:first-of-type {
    font-family: "Santander Text", Arial, Sans-Serif;
    color: $lightgrey;
    font-size: 1rem;
    padding: 0 0.5rem;
    background: #fafbfc;
    height: 3rem;
    border: 1px solid #eef0f2;
    border-bottom: 1px solid #8cbabd;
    border-radius: 0;
    width: 3rem;
  }
  input::placeholder {
    color: $lightgrey;
  }
}

.ps--form-disclaimer {
  display: inline-flex;
  label {
    font-size: 0.875rem;
    color: $darkgrey;
    font-weight: 300;
  }
  &.ps--form-descarga-aqui {
    p {
      font-size: 0.875rem;
      color: #444444;
      font-weight: 300;
    }
    strong {
      color: $main;
    }
    a {
      cursor: pointer;
    }
  }
}
.ps--radio-cita {
  font-weight: 300;
}
.ps--form-checkbox,
.ps--form-radio {
  .bi {
    font-size: 1rem;
    margin-right: 0.25rem;
    &:hover {
      cursor: pointer;
    }
    &.bi-check-square-fill,
    &.bi-record-circle {
      color: $darkaqua;
    }
  }
  input + label {
    .bi-check-square-fill,
    .bi-record-circle {
      display: none;
    }
    .bi-square,
    .bi-circle {
      display: inline-block;
    }
  }
  input:checked + label {
    .bi-square,
    .bi-circle {
      display: none;
    }
    .bi-check-square-fill,
    .bi-record-circle {
      display: inline-block;
    }
  }
  input:disabled + label {
    cursor: not-allowed;
    .bi-square,
    .bi-circle,
    .bi-check-square-fill,
    .bi-record-circle {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}
.ps--custom-select-horario .bi {
  select {
    appearance: none;
    padding-right: 2.6rem;
  }
  .bi {
    color: $darkaqua;
    font-size: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 0.45rem;
    border-left: 1px solid $input-border;
    padding-left: 0.45rem;
  }
}

.signature-btn-clear {
  background-color: #ec0000;
  border: 1px solid #ec0000;
  color: white;
  font-family: "Santander Text", Arial, Sans-Serif;
  font-size: 0.875rem;
  border-radius: 1.25rem;
  cursor: pointer;
  width: 6rem;
  height: 2rem;
}

canvas {
  border-style: dashed;
  border-width: 1px;
  margin-left: 8px;
}

.ps--form-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(113, 113, 113, 0.2);
  z-index: 999;
  top: 0;
  left: 0;
  .spinner-border {
    width: 4rem;
    height: 4rem;
    color: $main;
  }
}
.consentimiento {
  font-family: "Santander Text", Arial, Sans-Serif;
  h1 {
    font-size: 1.2rem;
    margin-bottom: 5rem;
    text-align: center;
    font-weight: 700;
  }
  .disclaimer {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 0.8rem;
  }

  .datosPersonales {
    border: 2px solid black;
    padding: 20px 5px;
    margin: 45px 0;
    span {
      display: block;
      font-size: 15px;
      color: $darkgrey;
    }
    h2 {
      text-decoration: underline;
      font-size: 18px;
      margin-bottom: 1.8rem;
    }
  }

  img {
    width: 180px;
    height: 34px;
    margin: 3rem 0 6rem 7rem;
  }
  .locate {
    margin-top: 12rem;
  }
  .sign {
    margin-top: 10rem;
    margin-left: -20rem;
  }
}

.ps--typedoc-remember {
  @media (max-width: 576px) {
    display: none;
  }
  p {
    font-size: 0.8rem;
    /**border: 1px solid #d0d0d0;*/
    box-shadow: 0px 4px 15px -5px rgb(0 0 0 / 75%);
    border-radius: 4px;
    position: relative;
    background: $darkaqua;
    margin-top: 12px;
    margin-left: 12px;
    padding: 0 8px;
    color: white;
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 6px;
    background: url("../../assets/images/triangle_dark_aqua.png") no-repeat
      center center;
    width: 30px;
    height: 20px;
  }
}

.ps--typedoc-remember-mobile {
  @media (min-width: 576px) {
    display: none;
  }
  p {
    font-size: 0.8rem;
    /**border: 1px solid #d0d0d0;*/
    box-shadow: 0px 4px 15px -5px rgb(0 0 0 / 75%);
    border-radius: 4px;
    position: relative;
    background: $darkaqua;
    margin-top: 12px;
    margin-left: 12px;
    padding: 0 8px;
    color: white;
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 6px;
    background: url("../../assets/images/triangle_dark_aqua.png") no-repeat
      center center;
    width: 30px;
    height: 20px;
  }
}
