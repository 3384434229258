.ps--docs-search {
  position: absolute;
  bottom: 1.6rem;
  right: 1.5rem;
  font-size: 1.5rem;
  z-index: 30;
  color: $main;
}
.ps--buscador-doc {
  input {
    font-family: $f-text;
    color: $grey;
    font-size: 1rem;
    padding: 0 0.5rem;
    background: $input-background;
    height: 3rem;
    width: 18rem;
    border: 1px solid $input-border;
    border-bottom: 1px solid $input-bottom-border;
    border-radius: 0;
  }
  .bi {
    color: $darkaqua;
    font-size: 1.5rem;
    position: absolute;
    right: 0.05rem;
    top: 0.45rem;
    border-left: 1px solid $input-border;
    padding-left: 0.45rem;
  }
}
