.ps--seguimiento {
  margin-bottom: 5rem;
  min-height: 46.5rem;
  .ps--form-back {
    text-transform: uppercase;
  }
}

.ps--seguimiento-title {
  font-size: 1rem;
  line-height: 1.3725rem;
  color: $darkgrey;
}

.ps--seguimiento-search {
  position: absolute;
  bottom: 1.6rem;
  right: 1.5rem;
  font-size: 1.5rem;
  z-index: 30;
  color: $darkgrey;
  &:hover {
    color: $main;
    cursor: pointer;
  }
  &.erase {
    right: 4.5rem;
    font-size: 1.6rem;
  }
  @media (max-width: 780px) {
    & {
      bottom: 3.4rem;
    }
    &.erase {
      right: 1.5rem;
      bottom: 1rem;
    }
  }
}

.ps--seguimiento-descarga {
  text-align: right;
  font-size: 0.85rem;
  color: $darkaqua;
  padding-bottom: 0.2rem;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.ps--seguimiento-box-container {
  position: relative;
}
.ps--factura-box-container-table {
  position: relative;
  margin-top: 2rem;
}
.ps--factura-box-container {
  position: relative;
}

.ps--seguimiento-activable {
  margin-bottom: 3rem;
}

.ps--seguimiento-form-activator {
  padding: 0 0.6rem 0.75rem 0.6rem;
  background: white;
  border: 1px solid $item-inner;
  border-radius: 50%;
  position: absolute;
  bottom: -1.6875rem;
  left: 50%;
  margin-left: -1.6875rem;
  z-index: 10;
  &::before {
    position: absolute;
    content: "";
    width: calc(100% + 2px);
    background: white;
    height: calc(50% - 1.5px);
    z-index: 11;
    top: -1px;
    left: -1px;
  }
  .bi {
    position: relative;
    z-index: 15;
    top: 0.55rem;
    font-size: 1.45rem;
    color: $darkaqua;
    &:hover {
      color: $darkgrey;
      cursor: pointer;
    }
  }
  .bi-dash-circle {
    display: none;
  }
}

.ps--seguimiento-activable {
  .no-gutters {
    & > .col,
    & > [class*="col-"] {
      padding-right: 6.5px;
      padding-left: 6.5px;
    }
  }
  &.active {
    .ps--seguimiento-form-activator {
      .bi-dash-circle {
        display: block;
      }
      .bi-plus-circle {
        display: none;
      }
    }
    .ps--activable-hidden {
      display: block;
    }
  }
  .ps--activable-hidden {
    display: none;
  }
}

.ps--seguimiento-table,
.ps--seguimiento-table-pagination,
.ps--seguimiento-title {
  font-family: $f-stext-only;
}

.ps--seguimiento-table {
  thead {
    tr {
      th {
        font-size: 0.625rem;
        font-weight: bold;
        border: none;
        border-right: 0px;
      }
    }
  }
  tbody {
    tr {
      &:first-of-type {
        td {
          border-top: 1px solid $dark;
          border-right: 0px;
        }
      }
      td {
        font-size: 0.75rem;
        color: $darkgrey;
        border-right: 0px;
        &.ps--seguimiento-table-date {
          min-width: 6.25rem;
        }
      }
    }
  }
}

.ps--seguimiento-table-pagination {
  font-size: 0.75rem;
  .ngx-pagination {
    margin: 0;
    padding: 0;
    li,
    a {
      border-radius: 3px;
    }
    .current {
      background: $darkaqua;
    }
  }
}
.ps--seguimiento-subtitle {
  text-overflow: ellipsis;
  font-family: $f-stext-only;
  color: #727272;
  font-size: 0.7rem;
}
// ESTILOS FACTURA
.ps--texto-p {
  text-overflow: ellipsis;
  font-family: $f-stext-only;
  color: #727272;
  font-size: 1rem;
}

.ps--documents-icon-read {
  em {
    font-size: 1.2rem;
  }
  &:hover {
    cursor: default;
  }
}

.spanBajaLead{
  color: $darkaqua;
  &:hover{
      cursor: pointer;
      text-decoration: underline;
  }
  &:nth-of-type(1){
      margin-right: 1rem;
  }
}

.modal-content{
  padding: 1rem;
}

.btnCloseModal, .purebah4{
  color: black;
  font-size: 2rem;
}

.pruebah4{
  font-family: $f-stext-only;
}

.btnCloseModal{
  position: relative;
  top: 0px;
  right: 0.7rem;
}

#obsBaja{
  height: 4.8rem;
  resize: vertical !important;
}

.formBajaLead{
  padding-left: 0.8rem;
}

.modalBodyFaltaMotivo{
  display: flex;
  flex-direction: column;
  align-items: center;
  p{
    margin-top: 1.3rem;
  }
}

.observacionesBajaDisabled{
  pointer-events: none;
  opacity: 0.5;
  background-color: #ccc;
}
.disabled-container{
  cursor: not-allowed !important;
}

.botonesConfirmacion{
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.eyeFill{
  vertical-align: middle;
}



@media (max-width: 540px){
    .tablaSeguimiento{
      overflow-x: auto;
    }

    .divItemsTipoPersona{
      display: block !important;
      width: 100%;
    }

    .activableFormSeguimiento{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
}

@media (max-width: 960px){
  .tablaSeguimiento{
    overflow-x: auto;
  }
  .divItemsTipoPersona{
    display: block !important;
    width: 100%;
  }
}

@media (max-width: 540px){
  .responsive-row{
    display: flex;
    flex-direction: column;
  }
  .responsive-col{
    flex: 0 0 100%;
    max-width: 100%;
  }
}
