// Santander Text
@font-face {
  font-family: "Santander Text";
  src: url("../../assets/fonts/SantanderTextW05-Light.eot");
  src: url("../../assets/fonts/SantanderTextW05-Light.eot?#iefix") format("eot"),
    url("../../assets/fonts/SantanderTextW05-Light.woff2") format("woff2"),
    url("../../assets/fonts/SantanderTextW05-Light.woff") format("woff"),
    url("../../assets/fonts/SantanderTextW05-Light.ttf") format("truetype");
  font-weight: 300;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Santander Text";
  src: url("../../assets/fonts/SantanderTextW05-LightIt.eot");
  src: url("../../assets/fonts/SantanderTextW05-LightIt.eot?#iefix")
      format("eot"),
    url("../../assets/fonts/SantanderTextW05-LightIt.woff2") format("woff2"),
    url("../../assets/fonts/SantanderTextW05-LightIt.woff") format("woff"),
    url("../../assets/fonts/SantanderTextW05-LightIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Santander Text";
  src: url("../../assets/fonts/SantanderTextW05-Regular.eot");
  src: url("../../assets/fonts/SantanderTextW05-Regular.eot?#iefix")
      format("eot"),
    url("../../assets/fonts/SantanderTextW05-Regular.woff2") format("woff2"),
    url("../../assets/fonts/SantanderTextW05-Regular.woff") format("woff"),
    url("../../assets/fonts/SantanderTextW05-Regular.ttf") format("truetype");
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Santander Text";
  src: url("../../assets/fonts/SantanderTextW05-Italic.eot");
  src: url("../../assets/fonts/SantanderTextW05-Italic.eot?#iefix")
      format("eot"),
    url("../../assets/fonts/SantanderTextW05-Italic.woff2") format("woff2"),
    url("../../assets/fonts/SantanderTextW05-Italic.woff") format("woff"),
    url("../../assets/fonts/SantanderTextW05-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Santander Text";
  src: url("../../assets/fonts/SantanderTextW05-Bold.eot");
  src: url("../../assets/fonts/SantanderTextW05-Bold.eot?#iefix") format("eot"),
    url("../../assets/fonts/SantanderTextW05-Bold.woff2") format("woff2"),
    url("../../assets/fonts/SantanderTextW05-Bold.woff") format("woff"),
    url("../../assets/fonts/SantanderTextW05-Bold.ttf") format("truetype");
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Santander Text";
  src: url("../../assets/fonts/SantanderTextW05-BoldItalic.eot");
  src: url("../../assets/fonts/SantanderTextW05-BoldItalic.eot?#iefix")
      format("eot"),
    url("../../assets/fonts/SantanderTextW05-BoldItalic.woff2") format("woff2"),
    url("../../assets/fonts/SantanderTextW05-BoldItalic.woff") format("woff"),
    url("../../assets/fonts/SantanderTextW05-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

// lato
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-Regular.eot");
  src: url("/assets/fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Lato-Regular.woff2") format("woff2"),
    url("/assets/fonts/Lato-Regular.woff") format("woff"),
    url("/assets/fonts/Lato-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-Italic.eot");
  src: url("/assets/fonts/Lato-Italic.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Lato-Italic.woff2") format("woff2"),
    url("/assets/fonts/Lato-Italic.woff") format("woff"),
    url("/assets/fonts/Lato-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-Light.eot");
  src: url("/assets/fonts/Lato-Light.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Lato-Light.woff2") format("woff2"),
    url("/assets/fonts/Lato-Light.woff") format("woff"),
    url("/assets/fonts/Lato-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-Semibold.eot");
  src: url("/assets/fonts/Lato-Semibold.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Lato-Semibold.woff2") format("woff2"),
    url("/assets/fonts/Lato-Semibold.woff") format("woff"),
    url("/assets/fonts/Lato-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-Bold.eot");
  src: url("/assets/fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Lato-Bold.woff2") format("woff2"),
    url("/assets/fonts/Lato-Bold.woff") format("woff"),
    url("/assets/fonts/Lato-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}
